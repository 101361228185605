import React, { useContext } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { mainContext } from './../context/mainContext';
import { IcHome, IcSubMenu } from '../../share/icons';
import { clearViewer } from '../../actions/viewer';

const Breadcrumb = ({ location }) => {
    const { project_name, logo_url } = useSelector(
        (state) => state.currentProject
    );
    const { name } = useSelector((state) => state.currentObject);

    const { removeAllPointClouds } = useContext(mainContext);

    const dispatch = useDispatch();

    return (
        <div className="justify-start items-center gap-0.5 flex">
            <Link
                to="/"
                className="text-modelic-gray-100 hover:text-modelic-primary-main"
            >
                <div
                    onClick={() => {
                        removeAllPointClouds();
                        dispatch(clearViewer());
                    }}
                    className="mr-1"
                >
                    {logo_url ? (
                        <img src={logo_url} alt="" className="h-8" />
                    ) : (
                        <div className="scale-150 mr-1">{IcHome}</div>
                    )}
                </div>
            </Link>
            <div className="text-modelic-gray-600 scale-150 mx-2">
                {IcSubMenu}
            </div>
            <div className="text-modelic-gray-600 text-base font-normal font-poppins uppercase">
                {project_name}
            </div>
            {location.pathname.includes('viewer') && (
                <>
                    <div className="text-modelic-gray-600 scale-150 mx-2">
                        {IcSubMenu}
                    </div>
                    <div className="text-modelic-gray-600 text-base font-normal font-poppins uppercase">
                        {name}
                    </div>
                </>
            )}
        </div>
    );
};

export default withRouter(Breadcrumb);
