import { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IcReset, IcSelectArrow, IcTickCircle } from '../../share/icons';
import {
    addNewIssue,
    emptyAllTeams,
    emptyCurrentIssue,
    getAllTeams,
    handleIssueEdit,
} from './../../actions/issue';
import { mainContext } from './../context/mainContext';
import { issueContext } from '../context/issueContext';
import locale from '../../utils/localization.json';
import { removeToolbars } from './../../actions/toolbar';

const IssueNew = () => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [assignee, setAssignee] = useState('');
    const [waiting, setWaiting] = useState(false);
    const [, forceUpdate] = useState();

    const lang = useSelector((state) => state.language);
    const viewer = useSelector((state) => state.viewer);
    const { urn, _id } = useSelector((state) => state.currentObject);
    const currentIssue = useSelector((state) => state.currentIssue);
    const assigneeTeams = useSelector((state) => state.assigneeTeams);
    const { markupsToolbar, closeToolbar } = useSelector(
        (state) => state.toolbars
    );

    const dispatch = useDispatch();

    const { validator } = useContext(mainContext);
    const {
        issueView,
        setIssueView,
        state,
        setState,
        markup,
        setMarkup,
        setScreenshot,
        handleCreateMarkup,
        handleRestoreScreenshot,
    } = useContext(issueContext);

    useEffect(() => {
        validator.current.messagesShown = false;
        if (issueView === 'new') {
            setScreenshot();
            handleCreateMarkup();
            dispatch(emptyCurrentIssue());
            dispatch(getAllTeams());
        } else if (issueView === 'edit') {
            setTitle(currentIssue.title);
            setDescription(currentIssue.description);
            setState(currentIssue.state);
            setMarkup(currentIssue.markup);
        } else if (issueView === 'clone') {
            setDescription(currentIssue.description);
            setState(currentIssue.state);
            setMarkup(currentIssue.markup);
            dispatch(getAllTeams());
        }
        return () => {
            dispatch(emptyAllTeams());
            dispatch(removeToolbars());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [issueView]);

    useEffect(() => {
        if (issueView === 'edit' || issueView === 'clone')
            handleRestoreScreenshot();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validator.current.allValid()) {
            setWaiting(true);

            closeToolbar?.setVisible(false);

            let markupExt = viewer?.getExtension(
                'Autodesk.Viewing.MarkupsCore'
            );

            const body = new FormData();
            body.append('model_id', _id);
            body.append('urn', urn);
            body.append('title', title.trim());
            body.append('description', description);
            body.append('markup', markup);
            body.append('state', JSON.stringify(state));
            body.append('assignee', assignee);
            body.append(
                'view_id',
                viewer?.model?.getDocumentNode()?.data?.guid
            );
            body.append(
                'view_role',
                viewer?.model?.getDocumentNode()?.data?.role
            );

            if (!markupsToolbar?.container?.classList.contains('adsk-hidden')) {
                setScreenshot(markupExt, markupsToolbar);

                body.append('state', JSON.stringify(viewer?.getState()));
                body.append('markup', markupExt?.generateData());
            }

            if (issueView === 'new')
                setTimeout(() => {
                    const canvas = document.getElementById('new-screenshot');

                    canvas?.toBlob(async (blob) => {
                        body.append('screenshot', blob, 'screenshot.png');

                        try {
                            markupExt?.leaveEditMode();
                            markupExt?.hide();
                            await dispatch(addNewIssue(body));
                            setIssueView('all');
                        } catch {
                            setWaiting(false);
                        }
                    }, 'image/png');
                }, 500);
            else {
                fetch(currentIssue.screenshot)
                    .then((res) => res.blob())
                    .then(async (blob) => {
                        body.append('screenshot', blob, 'screenshot.png');

                        try {
                            markupExt?.leaveEditMode();
                            markupExt?.hide();
                            await dispatch(addNewIssue(body));
                            setIssueView('all');
                        } catch {
                            setWaiting(false);
                        }
                    });
            }
        } else {
            validator.current.showMessages();
            forceUpdate(1);
        }
    };

    const handleEdit = async (e) => {
        e.preventDefault();
        setWaiting(true);

        closeToolbar?.setVisible(false);

        const markupExt = viewer?.getExtension('Autodesk.Viewing.MarkupsCore');
        markupExt?.hide();

        const body = {};
        if (title && title !== currentIssue.title) body.title = title;
        if (description && description !== currentIssue.description)
            body.description = description;

        try {
            await dispatch(handleIssueEdit(body));
            setIssueView('all');
        } catch {
            setWaiting(false);
        }
    };

    return (
        <div>
            {issueView === 'new' && (
                <button
                    className="absolute top-3 right-7 flex items-center gap-2 text-modelic-gray-400 hover:text-modelic-primary-main text-sm font-medium font-poppins leading-none"
                    onClick={() => {
                        closeToolbar?.setVisible(false);

                        setScreenshot();
                        handleCreateMarkup();
                        setTitle('');
                        setDescription('');
                    }}
                >
                    {locale[lang].reset}
                    {IcReset}
                </button>
            )}
            <form
                onSubmit={
                    issueView === 'new' || issueView === 'clone'
                        ? handleSubmit
                        : handleEdit
                }
                className="inline-flex flex-col gap-4 w-full"
            >
                {issueView === 'new' ? (
                    <canvas
                        id="new-screenshot"
                        className="w-full rounded-lg hover:cursor-pointer"
                        onClick={handleRestoreScreenshot}
                    ></canvas>
                ) : (
                    <img
                        className="w-full rounded-lg hover:cursor-pointer"
                        alt="screenshot"
                        src={currentIssue.screenshot}
                        onClick={handleRestoreScreenshot}
                    />
                )}
                <div>
                    <label
                        htmlFor="issue-title"
                        className="text-modelic-gray-300 block mb-1 text-xs font-normal font-poppins"
                    >
                        {locale[lang].title}
                    </label>
                    <input
                        id="issue-title"
                        className="block w-full px-3 py-2 outline-0 text-sm font-normal font-poppins bg-modelic-gray-900 text-modelic-gray-500 border border-modelic-gray-600 rounded"
                        placeholder={locale[lang].enterTitle}
                        onChange={(e) => setTitle(e.target.value)}
                        value={title}
                    />
                    {validator.current.message('title', title, [
                        'required',
                        {
                            cdn: ['"`&()/\\.;<>|'],
                        },
                    ])}
                </div>
                {assigneeTeams && (
                    <div className="relative">
                        <label
                            htmlFor="issue-assignee"
                            className="text-modelic-gray-300 block mb-1 text-xs font-normal font-poppins"
                        >
                            {locale[lang].assignee}
                        </label>
                        <div className="absolute right-4 top-8 text-modelic-gray-500">
                            {IcSelectArrow}
                        </div>
                        <select
                            id="issue-assignee"
                            className="bg-modelic-gray-900 block w-full px-3 py-2 outline-0 text-sm font-normal font-poppins text-modelic-gray-500 border border-modelic-gray-600 rounded"
                            value={
                                assignee !== ''
                                    ? assignee
                                    : locale[lang].selectAssignee
                            }
                            onChange={(e) => setAssignee(e.target.value)}
                        >
                            <option
                                value={locale[lang].selectAssignee}
                                disabled
                            >
                                {locale[lang].selectAssignee}
                            </option>
                            {assigneeTeams?.map(({ _id, name }) => (
                                <option key={_id} value={_id}>
                                    {name}
                                </option>
                            ))}
                        </select>
                    </div>
                )}
                <div>
                    <label
                        htmlFor="issue-description"
                        className="text-modelic-gray-300 block mb-1 text-xs font-normal font-poppins"
                    >
                        {locale[lang].description}
                    </label>
                    <textarea
                        id="issue-description"
                        rows="4"
                        className="block w-full px-3 py-2 outline-0 text-sm font-normal font-poppins bg-modelic-gray-900 text-modelic-gray-500 border border-modelic-gray-600 rounded"
                        placeholder={locale[lang].enterDescription}
                        onChange={(e) => setDescription(e.target.value)}
                        value={description}
                    ></textarea>
                    {validator.current.message(
                        'description',
                        description,
                        'required|max:200'
                    )}
                </div>
                <button
                    type="submit"
                    disabled={waiting}
                    className="w-full h-8 rounded flex gap-3 justify-center items-center bg-modelic-primary-main text-modelic-gray-100 text-sm font-medium font-poppins leading-none"
                >
                    {issueView === 'edit'
                        ? locale[lang].edit
                        : locale[lang].submit}
                    {IcTickCircle}
                </button>
                <button
                    type="button"
                    disabled={waiting}
                    className="w-full h-8 rounded border border-modelic-primary-main justify-center items-center text-modelic-primary-main text-sm font-medium font-poppins leading-none"
                    onClick={() => {
                        setIssueView('all');

                        let markupExt = viewer?.getExtension(
                            'Autodesk.Viewing.MarkupsCore'
                        );
                        markupExt?.leaveEditMode();
                        markupExt?.hide();

                        markupsToolbar?.setVisible(false);
                        closeToolbar?.setVisible(false);
                    }}
                >
                    {locale[lang].close}
                </button>
            </form>
        </div>
    );
};

export default IssueNew;
